import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

function RSVP() {
    const initialFormState = {
        name: "",
        attending: true, // Default to "Attending"
    };

    const savedData =
        JSON.parse(localStorage.getItem("rsvpFormData")) || initialFormState;

    const [formData, setFormData] = useState(savedData);
    const [step, setStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const totalSteps = 2;

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem("rsvpFormData", JSON.stringify(formData));
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const toggleAttending = () => {
        setFormData((prev) => ({
            ...prev,
            attending: !prev.attending,
        }));
    };

    const handleNext = () => {
        if (step === 1 && formData.name.trim() === "") {
            alert("Please enter your name to proceed.");
            return;
        }
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return;

        if (formData.name.trim() === "") {
            alert("Please enter your name before submitting.");
            return;
        }

        setIsSubmitting(true);

        try {
            await fetch(
                "https://script.google.com/macros/s/AKfycbz24rCQHTq0843tgApo0NcX-umBJaxWYM8BuuyeQC_Eeamf7lUzPZI5Mg4LXGiMJybomQ/exec",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "no-cors",
                    body: JSON.stringify(formData),
                }
            );

            // Clear localStorage and reset the form
            localStorage.removeItem("rsvpFormData");
            setFormData(initialFormState);
            setStep(1);

            // Redirect to Thank You page and pass attending state
            navigate("/thank-you", { state: { attending: formData.attending } });
        } catch (error) {
            console.error("Error submitting RSVP:", error);
            alert(
                "An error occurred while submitting your RSVP. Please check your network connection or try again later."
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    const progressPercentage = (step / totalSteps) * 100;

    const variants = {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 },
    };

    return (
        <div className="flex items-center justify-center flex-col min-h-screen">
            <h1 className="text-7xl font-bold mb-4 text-[#476a4c] mb-[50px]">
                RSVP Details
            </h1>
            <form
                className="w-full max-w-md bg-white p-8 rounded shadow-md text-center"
                noValidate
            >
                {/* Content here... */}
                <AnimatePresence mode="wait">
                    {step === 1 && (
                        <motion.div
                            key="step1"
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.5 }}
                        >
                            <p className="text-sm text-gray-500 mb-4">
                                Question 1 of {totalSteps}
                            </p>
                            <label className="block mb-2 text-[40px] font-bold mb-4 text-[#476a4c]">
                                Your Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="w-full p-2 border border-gray-300 rounded mb-6"
                            />
                        </motion.div>
                    )}

                    {step === 2 && (
                        <motion.div
                            key="step2"
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.5 }}
                        >
                            <p className="text-sm text-gray-500 mb-4">
                                Question 2 of {totalSteps}
                            </p>
                            <label className="block mb-2 text-[40px] font-bold mb-4 text-[#476a4c]">
                                Attendance
                            </label>
                            <div
                                className="flex items-center justify-center w-full"
                                onClick={toggleAttending}
                            >
                                <div
                                    className={`flex items-center w-[235px] h-12 rounded-full p-1 cursor-pointer relative mb-[50px] ${
                                        formData.attending
                                            ? "bg-[#476a4c]"
                                            : "bg-red-200"
                                    }`}
                                >
                                    <span
                                        className={`absolute text-sm font-medium left-4 z-50 ${
                                            formData.attending
                                                ? "text-white"
                                                : "!text-red-600"
                                        }`}
                                    >
                                        Not Attending
                                    </span>
                                    <span
                                        className={`absolute text-sm font-medium right-[30px] z-50 ${
                                            formData.attending
                                                ? "!text-[#476a4c]"
                                                : "text-white"
                                        }`}
                                    >
                                        Attending
                                    </span>
                                    <div
                                        className={`bg-white w-28 h-10 rounded-full shadow-md transition-transform duration-300 ${
                                            formData.attending
                                                ? "transform translate-x-[100%]"
                                                : "transform translate-x-0"
                                        }`}
                                    ></div>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Navigation Buttons */}
                <div className="flex justify-between mt-4">
                    {step > 1 && (
                        <button
                            type="button"
                            onClick={handleBack}
                            className="p-2 border border-purple-500 text-purple-500 rounded"
                        >
                            Back
                        </button>
                    )}
                    {step < totalSteps ? (
                        <button
                            type="button"
                            onClick={handleNext}
                            className="p-2 right-0 border border-purple-500 text-purple-500 rounded"
                        >
                            Next
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="p-2 border border-purple-500 text-purple-500 rounded"
                            disabled={isSubmitting}
                        >
                            Submit RSVP
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default RSVP;
