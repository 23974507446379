import React from 'react';

const Contact = () => {

    return (
        <div className="p-6 min-h-screen flex items-center flex-col justify-center">
            <h1 className="text-7xl font-bold mb-12 text-[#476a4c]">Contact Us</h1>
            <p className="text-center mt-4 text-gray-600">
                <ul>
                    <li className="text-center mt-4 text-gray-600">Tel: 07479939083</li>
                    <li className="text-center mt-4 text-gray-600">Email: lukefish88@gmail.com</li>
                </ul>
            </p>
        </div>
    );
};

export default Contact;
