import React, { useState, useEffect } from "react";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Welcome from "./pages/Welcome";
import RSVP from "./pages/RSVP";
import Schedule from "./pages/Schedule";
import Accommodation from "./pages/accommodation";
import DirectionsPage from "./pages/Directions";
import Contact from "./pages/Contact";
import ThankYou from "./pages/thank-you";

function App() {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false); // Tracks if the user is authorized
    const [password, setPassword] = useState(""); // Tracks the input password

    const correctPassword = "3RN13_P00"; // Replace this with your desired password

    // Check localStorage on component mount
    useEffect(() => {
        const authState = localStorage.getItem("isAuthorized");
        if (authState === "true") {
            setIsAuthorized(true);
        }
    }, []);

    const handleLogin = () => {
        if (password === correctPassword) {
            setIsAuthorized(true);
            localStorage.setItem("isAuthorized", "true"); // Save to localStorage
        } else {
            alert("Incorrect password!");
        }
    };

    const handleLogout = () => {
        setIsAuthorized(false);
        localStorage.removeItem("isAuthorized"); // Clear localStorage
    };

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    if (!isAuthorized) {
        // Render the password prompt if not authorized
        return (
            <div className="min-h-screen flex items-center justify-center background-filter">
                <div className="text-center">
                    <h1 className="text-7xl font-bold text-[#476a4c]">Password Protected</h1>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                        className="border mt-4 border-2 p-2 rounded mb-4"
                    />
                    <br />
                    <button
                        onClick={handleLogin}
                        className="border-2 py-2 px-8 border-[#a300ff60] text-[#a300ff] tracking-widest font-extralight"
                    >
                        Submit
                    </button>
                </div>
            </div>
        );
    }

    // Render the main application if authorized
    return (
        <div className="min-h-screen background-filter relative bg-cover text-center">


            {/* Menu Icon */}
            <button
                className="fixed top-4 right-4 z-20 flex flex-col items-center justify-between w-[20px] h-[15px]"
                onClick={toggleMenu}
                aria-label="Toggle menu"
            >
                <div
                    className={`w-full h-[1px] bg-gray-800 transition-transform transform ${
                        isMenuOpen ? "rotate-45 translate-y-2 !bg-white" : ""
                    }`}
                />
                <div
                    className={`w-full h-[1px] bg-gray-800 transition-opacity ${
                        isMenuOpen ? "opacity-0" : "opacity-100"
                    }`}
                />
                <div
                    className={`w-full h-[1px] bg-gray-800 transition-transform transform ${
                        isMenuOpen ? "-rotate-45 -translate-y-2 !bg-white" : ""
                    }`}
                />
            </button>

            {/* Off-Canvas Menu */}
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        initial={{ x: "100%" }}
                        animate={{ x: 0 }}
                        exit={{ x: "100%" }}
                        transition={{ duration: 0.5 }}
                        className="fixed top-0 right-0 w-64 h-full bg-gray-800 text-white shadow-lg z-10 p-6 space-y-6 flex flex-col text-center"
                    >
                        <button
                            onClick={toggleMenu}
                            className="self-end text-gray-400 text-sm mb-4"
                        />
                        <Link to="/" onClick={toggleMenu} className="text-lg ">
                            Home
                        </Link>
                        <Link to="/rsvp" onClick={toggleMenu} className="text-lg ">
                            RSVP
                        </Link>
                        <Link to="/schedule" onClick={toggleMenu} className="text-lg">
                            Schedule
                        </Link>
                        <Link to="/directions" onClick={toggleMenu} className="text-lg ">
                            Directions
                        </Link>
                        <Link to="/accommodation" onClick={toggleMenu} className="text-lg ">
                            Accommodation
                        </Link>
                        <Link to="/contact" onClick={toggleMenu} className="text-lg ">
                            Contact Us
                        </Link>
                        {/* Logout Button */}
                        <button
                            onClick={handleLogout}
                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                        >
                            Logout
                        </button>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Animated Page Content */}
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route
                        path="/"
                        element={
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.5 }}
                            >
                                <Welcome />
                            </motion.div>
                        }
                    />
                    <Route
                        path="/thank-you"
                        element={
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.5 }}
                            >
                                <ThankYou />
                            </motion.div>
                        }
                    />
                    <Route
                        path="/contact"
                        element={
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.5 }}
                            >
                                <Contact />
                            </motion.div>
                        }
                    />
                    <Route
                        path="/rsvp"
                        element={
                            <motion.div
                                initial={{ opacity: 0, x: 50 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -50 }}
                                transition={{ duration: 0.5 }}
                            >
                                <RSVP />
                            </motion.div>
                        }
                    />
                    <Route
                        path="/schedule"
                        element={
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.5 }}
                            >
                                <Schedule />
                            </motion.div>
                        }
                    />
                    <Route
                        path="/directions"
                        element={
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.5 }}
                            >
                                <DirectionsPage />
                            </motion.div>
                        }
                    />
                    <Route
                        path="/accommodation"
                        element={
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.5 }}
                            >
                                <Accommodation />
                            </motion.div>
                        }
                    />
                </Routes>
            </AnimatePresence>

            {/* Bottom Navigation */}
            <nav className="p-4 flex fixed items-center space-x-2 content-end w-full justify-center bottom-0">
                Made with <span className="text-red-900 font-sans px-1"> &hearts; </span> by Luke & Charlotte
            </nav>
        </div>
    );
}

export default App;
