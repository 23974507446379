import React from "react";
import { useLocation } from "react-router-dom";

const ThankYou = () => {
    const location = useLocation();
    const attending = location.state?.attending;

    return (
        <div className="flex items-center justify-center flex-col min-h-screen">
            <h1 className="text-7xl font-bold text-[#476a4c]">Thank You!</h1>
            {attending ? (
                <>
                    <p className="text-lg mt-4">We can't wait to see you at the wedding!</p>
                    <img src="https://media1.tenor.com/m/m5r7z7YCB1IAAAAd/smile.gif"
                         className="max-w-[700px] mx-auto w-full px-6"/>
                </>
            ) : (
                <>
                    <p className="text-lg mt-4">We'll miss you at the wedding!</p>
                    <img src="https://media1.tenor.com/m/7n5OC9_LqqEAAAAd/%DA%AF%D8%B1%DB%8C%D9%87-%D9%86%D8%A7%D8%B1%D8%A7%D8%AD%D8%AA.gif"
                         className="max-w-[700px] mx-auto w-full px-6"/>
                </>
            )}
        </div>
    );
};

export default ThankYou;

